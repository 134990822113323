<script lang="ts">
    import * as m from "$m";
</script>

<p class="text-xs text-gray-500 dark:text-gray-400">
    © 2024 Robots Will Take Over The World AS. {m.All_rights_reserved()}.
</p>
<div class="sm:ml-auto flex gap-4 sm:gap-6">
    <a class="text-xs hover:underline underline-offset-4" href="/terms">
        {m.terms_of_sale()}
    </a>
    <a class="text-xs hover:underline underline-offset-4" href="/privacy">
        {m.privacy()}
    </a>
</div>
